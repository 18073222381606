import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import axios from 'axios'
import { PrivateRoute } from './helpers/privateRoute'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/signin'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {

  render() {
    localStorage.getItem('token')?axios.defaults.headers.common['x-auth-token']=localStorage.getItem('token'):axios.defaults.headers.common['x-auth-token']="null";
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<PrivateRoute><Login /></PrivateRoute>} />
            <Route exact path="/register" name="Register Page" element={<PrivateRoute><Register /></PrivateRoute>} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<PrivateRoute><DefaultLayout /></PrivateRoute>} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App

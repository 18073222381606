import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { CSidebar, CSidebarNav, CSidebarToggler ,CNavItem } from '@coreui/react'
import { cilPowerStandby, cilSpa, cilSpeech, cilPeople, cilAddressBook } from '@coreui/icons'

import { AppSidebarNav } from './AppSidebarNav'



import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import { NavLink } from 'react-router-dom'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      {/* <CSidebarBrand className="d-none d-md-flex" to="/">
        <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand> */}
      <CSidebarNav>
        <SimpleBar>

          <AppSidebarNav  items={navigation} />
          {/* <CNavItem active key={12} name='Logout'  href='/logout' >
          <CIcon customClassName="nav-icon" icon={cilPowerStandby} />
          Logout
        </CNavItem> */}
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow   })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)

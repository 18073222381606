import React from 'react';
import { Navigate, Outlet , useLocation , useNavigate } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import { AppContent } from 'src/components';
export const PrivateRoute = ({children}) => {
    const token = localStorage.getItem("token"); // determine if authorized, from context or however you're doing it
    const { decodedToken, isExpired } = useJwt(token);
    const location = useLocation()
    const navigate = useNavigate()
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    //console.log(window.location )
    //console.log(location )
    if(window.location.pathname =="/login"){
      //console.log(token && !isExpired);
      return token && !isExpired ? navigate(-1) : children;
    }else if(window.location.pathname =="/register"){
      //console.log(token && !isExpired);
      return token && !isExpired ? navigate(-1) : children;
    }else{

      return token && !isExpired ? children : <Navigate to="/login" />;
    }
}




// import React, { Component } from 'react';
// import { Route, Navigate } from 'react-router-dom';
// import jwt_decode from 'jwt-decode';

// class PrivateRoute extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isAuthenticated: false,
//       isTokenValid: false,
//       isLoading: true,
//     };
//   }

//   componentDidMount() {
//     // Check authentication and token validity here
//     const token = localStorage.getItem('token'); // Replace with your token retrieval logic

//     if (token) {
//       const decodedToken = jwt_decode(token);
//       const currentTime = Date.now() / 1000;
//       const isTokenValid = decodedToken.exp > currentTime;
//       this.setState({ isAuthenticated: true, isTokenValid });
//     }

//     this.setState({ isLoading: false });
//   }

//   render() {
//     const { isAuthenticated, isTokenValid, isLoading } = this.state;
//     const { component: Component, ...rest } = this.props;

//     if (isLoading) {
//       // Render a loading indicator or skeleton component while checking authentication
//       return <div>Loading...</div>;
//     }

//     return (

//       <Route
//         {...rest}
//         render={(props) => {
//           if (isAuthenticated && isTokenValid) {
//             // Render the protected component if authenticated and token is valid
//             return <Component {...props} />;
//           } else {
//             // Redirect to the login page if not authenticated or token is expired
//             return <Navigate to="/login" />;
//           }
//         }}
//       />

//     );
//   }
// }

// export default PrivateRoute;


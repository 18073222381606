import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Category = React.lazy(() => import('./views/category'))
const City = React.lazy(() => import('./views/city'))
const Amenity = React.lazy(() => import('./views/amenity'))
const FAQ = React.lazy(() => import('./views/FAQ'))
const Subquestion = React.lazy(() => import('./views/subQuestion'))
const EditQuestion = React.lazy(() => import('./views/questionEdit'))
const Rule = React.lazy(() => import('./views/rule'))
const Roles = React.lazy(() => import('./views/roles'))
const List = React.lazy(() => import('./views/list'))
const ListDetails = React.lazy(() => import('./views/listDetails'))
const Property = React.lazy(() => import('./views/property'))
const PropertyDetails = React.lazy(() => import('./views/propertyDetails'))
const Guest = React.lazy(() => import('./views/guest'))
const Booking = React.lazy(() => import('./views/booking'))
const Calendar = React.lazy(() => import('./views/calendar'))
const Logout = React.lazy(() => import('./views/logout'))
const Users = React.lazy(() => import('./views/users'))
const Access = React.lazy(() => import('./views/access'))

const routes = [
  { path: '/', exact: true, name: 'Calendar' },
  { path: '/dashboard', name: 'Calendar', element: Calendar },
  { path: '/booking', name: 'Booking', element: Booking, exact: true },
  { path: '/calendar', name: 'Calendar', element: Calendar, exact: true },
  { path: '/city', name: 'City', element: City, exact: true },
  { path: '/category', name: 'Category', element: Category, exact: true },
  { path: '/amenity', name: 'Amenity', element: Amenity, exact: true },
  { path: '/faq', name: 'FAQ', element: FAQ, exact: true },
  { path: '/guest', name: 'Guest', element: Guest, exact: true },
  { path: '/list', name: 'List', element: List, exact: true },
  { path: '/property', name: 'Property', element: Property, exact: true },
  { path: '/property/details/:id', name: 'PropertyDetails', element: PropertyDetails, exact: true },
  { path: '/question/edit/:id', name: 'EditQuestion', element: EditQuestion, exact: true },
  { path: '/rule', name: 'Rule', element: Rule, exact: true },
  { path: '/roles', name: 'Roles', element: Roles, exact: true },
  { path: '/users', name: 'Users', element: Users, exact: true },
  { path: '/access', name: 'Access', element: Access, exact: true },
  { path: '/subquestion/:id', name: 'Subquestion', element: Subquestion, exact: true },
  { path: '/list/details/:id', name: 'ListDetails', element: ListDetails, exact: true },
  { path: '/logout', name: 'Logout', element: Logout, exact: true },
]

export default routes

import React, { useEffect, useLayoutEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useJwt } from 'react-jwt';
import { CBadge,CSpinner } from '@coreui/react'
import { Url } from 'src';
import axios from 'axios'
export const AppSidebarNav = ({ items }) => {
  const location = useLocation()
  const [roles , setRoles]= useState([])
  const [access , setAccess]= useState([])
  const [accessData , setAccessData]= useState([])
  const [loadings , setLoadings]= useState(false)
  //console.log(items);

  const token = localStorage.getItem('token');
  const { decodedToken, isExpired } = useJwt(token);
  //console.log("decodedToken", decodedToken);
  useLayoutEffect(() => {
  //console.log("access",access);
  getDataOfItems()
  if(access){
    setLoadings(false);
  }
  }, [access])

  const getDataOfItems =() => {
    let arr = []
    if (access?.length > 0) {
     //console.log(access);
      for (let index = 0; index < items.length; index++) {
        for (let i = 0; i < access?.length; i++) {
          if(items[index].name?.toLowerCase() === access[i].name.en?.toLowerCase() ){
            arr.push(items[index])
          }
        }

      }
      setAccessData([...arr])
    }
  }
  useLayoutEffect(()=>{
  //console.log("roles",roles.filter(role=> role?._id === decodedToken.role)[0]?.access);
  setAccess(roles.filter(role=> role?._id === decodedToken.role)[0]?.access)

  },[roles])

  useLayoutEffect(() => {
    getRoles()
  }, [])
 const getRoles = async () => {
  try{
    setLoadings(true)
    const { data } = await axios.get(Url+'/api/role', {
      headers: {
        lang: localStorage.getItem('lang'),
      },
    })
    //console.log(data.roles)
    if (data?.roles?.length > 0) {
     const arr=data.roles
     //console.log(arr);
      setRoles([...arr])
    }

  }catch(err){
    //console.log(err);
  }

  }
  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }
  const navLogoutItem = (item, index) => {
    //console.log("items",items);

    // const Items =items?.filter((i)=>i.name?.toLowerCase()===item?.name?.en.toLowerCase())
    // //console.log(Items);
    const { component, name, badge, icon, ...rest } =item
    const Component = component
    // //console.log(rest);
    // //console.log(index);
    // //console.log(access);
    // if(access){
    //   //console.log(access[index]?.name);
    // }
    //console.log(access&&access.some(access=>access.name.en.toLowerCase()===item.name.toLowerCase()));
    return (

      <Component
        {...(rest.to && !rest.items && { component: NavLink,})}
        key={index}
        {...rest}
        style={{display:access&&access.some(access=>access.name.en.toLowerCase()===item.name.toLowerCase())?"":"none"}}
        active={item.to === window.location.pathname || (item.to=="/calendar" && window.location.pathname=="/dashboard" ) }
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }

  const navItem = (item, index) => {
    //console.log("items",items);

    // const Items =items?.filter((i)=>i.name?.toLowerCase()===item?.name?.en.toLowerCase())
    // //console.log(Items);
    const { component, name, badge, icon, ...rest } =item
    const Component = component
    // //console.log(rest);
    // //console.log(index);
    // //console.log(access);
    // if(access){
    //   //console.log(access[index]?.name);
    // }
    return (
      <Component
        {...(rest.to && !rest.items && { component: NavLink,})}
        key={index}
        {...rest}
        active={item.to === window.location.pathname}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item[index]
    const Component = component
    //console.log(location.pathname.startsWith(to));
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {loadings?
      <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",margin:"320px auto"}}>
        <CSpinner color="secondary" />
      </div>:<>
      {items &&items.map((item, index) => (item.items ? navGroup(item, index) : navLogoutItem(item, index)))}</>}

        {/* {accessData.length>0 &&
        accessData.map((item, index) => (!access ? navGroup(item, index) : navLogoutItem(item, index)))} */}
        {!loadings&&access&&items.filter(item => item.name === "Logout").map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
